body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
  height: 100%;
  font-weight: 700;
  letter-spacing: normal;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

svg {
  overflow: visible;
}
/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: rgb(233, 240, 243);
}

::-webkit-scrollbar-thumb {
  background-color: #90a4ae;
  border-radius: 6px;
  border: 2px solid rgb(233, 240, 243);
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Add left padding for legend icons (for hebrew compatibility) */
.recharts-legend-item-text {
  padding-right: 5px;
}
